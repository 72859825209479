import '../styles/globals.scss'
import { IntlProvider } from 'react-intl'
import { useRouter } from 'next-translate-routes/router'
import { Provider } from 'react-redux'
import store from 'redux/store'
import { useEffect, useLayoutEffect, useState } from 'react'
import { setCookie } from '@/helpers/utils'
import { withTranslateRoutes } from 'next-translate-routes'
import { AppProps } from 'next/app'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const [messages, setMessages] = useState({})
  const { locale, query } = router
  const localeWithQuery = query.lang && query.lang !== locale ? query.lang as string : locale

  useEffect(() => {
    setCookie('travel_language', localeWithQuery, {
      domain: '.' + process.env.NEXT_PUBLIC_TRAVEL_DOMAIN.split('.').splice(1).join('.'),
      path: '/',
    })
  }, [locale, query.lang])

  useLayoutEffect(() => {
    async function setTranslations() {
      if(localeWithQuery !== 'en') {
        const path = `travel_ssr-${localeWithQuery}`
        const data = await import(`../translations/${path}.json`)
        const messagesString = data[path] || {}
        setMessages(messagesString)
      }
    }

    setTranslations()
  }, [locale])

  return (<IntlProvider
    locale={localeWithQuery === 'ach' ? 'en' : localeWithQuery}
    messages={localeWithQuery && localeWithQuery !== 'en' ? messages : {}}
  >
    <Provider store={store}>
      <Component {...pageProps} />
    </Provider>
  </IntlProvider>)
}

export default withTranslateRoutes(JSON.parse(`{"defaultLocale":"en","locales":["en","de","fr","es","it","ach"],"routesTree":{"name":"","paths":{"default":""},"children":[{"name":"index","paths":{"default":"index"}},{"name":"account","paths":{"default":"account"},"children":[{"name":"activation","paths":{"default":"activation"},"children":[{"name":"[key]","paths":{"default":":key"},"children":[{"name":"index","paths":{"default":"index"}}]}]}]},{"name":"booking","paths":{"default":"booking"},"children":[{"name":"[uuid]","paths":{"default":":uuid"},"children":[{"name":"confirm","paths":{"default":"confirm"}},{"name":"reject","paths":{"default":"reject"}},{"name":"[resortShopType]","paths":{"default":":resortShopType"},"children":[{"name":"confirm","paths":{"default":"confirm"}},{"name":"reject","paths":{"default":"reject"}}]}]}]},{"name":"bookings","paths":{"default":"bookings","de":"buchungen","fr":"reservations","it":"prenotazioni","es":"reservas"},"children":[{"name":"[bookingId]","paths":{"default":":bookingId"},"children":[{"name":"[guestId]","paths":{"default":":guestId"}}]}]},{"name":"landing_page","paths":{"default":"landing_page"},"children":[{"name":"[landing_id]","paths":{"default":":landing_id"},"children":[{"name":"config","paths":{"default":"config"}},{"name":"index","paths":{"default":"index"}}]}]},{"name":"widget","paths":{"default":"widget"},"children":[{"name":"[partner_id]","paths":{"default":":partner_id"}}]}]},"fallbackLng":{}}`), MyApp)
