// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://5467671c802d4f07a20083d833f57b83@err.djangostars.com/38',
  ignoreErrors: [
    'AbortError',
    'UnhandledRejection',
    'Unexpected token o in JSON at position 1',
    'Anonymous function(/gtm.js)',
    'undefined is not an object (evaluating \'d.appendChild\')',
    'chrome-extension',
    'gtm',
    'Illegal invocation',
    'Can\'t find variable: setIOSParameters',
    '(app-modern)',
    'd is undefined',
    'Non-Error promise rejection captured with keys: error, status',
    `Cannot read properties of undefined (reading 'appendChild')`,
  ],
  beforeSend(event) {
    if(
      event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
        f.filename?.includes(`https://www.googletagmanager.com/gtm.js`),
      )
    ) {
      return null
    }
  },
  // Adjust this value in production, or use tracesSampler for greater control
  // tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
