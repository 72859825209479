/* eslint-disable no-case-declarations */
import { createAction } from 'redux-actions'

const AVAILABILITY_ITEMS = 'AVAILABILITY_ITEMS'
const setAvailabilityItems = createAction(AVAILABILITY_ITEMS)

const UPDATE_AVAILABILITY_ITEMS_LEFT = 'UPDATE_AVAILABILITY_ITEMS_LEFT'
const updateAvailabilityItemsLeft = createAction(UPDATE_AVAILABILITY_ITEMS_LEFT)

const UPDATE_ROOM_AVAILABILITY = 'UPDATE_ROOM_AVAILABILITY'
const updateRoomAvailability = createAction(UPDATE_ROOM_AVAILABILITY)

const CLEAR_ROOM_AVAILABILITY = 'CLEAR_ROOM_AVAILABILITY'
const clearRoomAvailability = createAction(CLEAR_ROOM_AVAILABILITY)

function availabilityInfo(state = {}, {
  type,
  payload,
}) {
  switch (type) {
    case AVAILABILITY_ITEMS:
      return {
        ...state,
        ...(payload || {}),
      }
    case UPDATE_AVAILABILITY_ITEMS_LEFT: {
      const newState = { ...state }
      if(newState[payload.id]) {
        newState[payload.id] = {
          ...newState[payload.id],
          roomId: payload.roomId,
          itemsLeft: payload.itemsLeft,
        }
      } else {
        newState[payload.id] = payload
      }
      return {
        ...newState,
      }
    }
    case UPDATE_ROOM_AVAILABILITY: {
      const newState = { ...state }
      if(newState[payload.id]) {
        newState[payload.id] = {
          ...newState[payload.id],
          roomId: payload.roomId,
          isBookable: payload.isBookable,
        }
      } else {
        newState[payload.id] = payload
      }
      return {
        ...newState,
      }
    }
    case CLEAR_ROOM_AVAILABILITY: {
      return {}
    }
    default:
      return state
  }
}

export {
  setAvailabilityItems,
  updateAvailabilityItemsLeft,
  updateRoomAvailability,
  availabilityInfo,
  clearRoomAvailability,
}
