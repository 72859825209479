import { createAction } from 'redux-actions'
import { getCookie, setCookie } from '@/helpers/utils'
import { User } from '../../interfaces/User'

const SET_CURRENCY = 'SET_CURRENCY'
const setCurrency = createAction(SET_CURRENCY)

function selectedCurrency(state = getCookie('currency_code') || '', {
  type,
  payload,
}): string {
  switch (type) {
    case SET_CURRENCY:
      setCookie('currency_code', payload)
      return payload
    default:
      return state
  }
}
const SET_USER = 'SET_USER'
const setUser = createAction<User>(SET_USER)

function currentUser(state = null, {
  type,
  payload,
}): User | null {
  switch (type) {
    case SET_USER:
      return payload
    default:
      return state
  }
}

const SET_IS_LOGGEDIN = 'SET_IS_LOGGEDIN'
const setIsLoggedIn = createAction(SET_IS_LOGGEDIN)

function isLoggedIn(state = false, {
  type,
  payload,
}) {
  switch (type) {
    case SET_IS_LOGGEDIN:
      return payload
    default:
      return state
  }
}

const SET_INFO = 'SET_INFO'
const setInfo = createAction(SET_INFO)

function info(state = {}, { type, payload }) {
  switch (type) {
    case SET_INFO:
      return payload
    default:
      return state
  }
}

const SET_POPUP_HIDDEN = 'SET_POPUP_HIDDEN'
const setPopupHidden = createAction(SET_POPUP_HIDDEN)

function isPopupHidden(state = true, {
  type,
  payload,
}) {
  switch (type) {
    case SET_POPUP_HIDDEN:
      return payload
    default:
      return state
  }
}

const CURRENCY_RATES = 'CURRENCY_RATES'
const setRates = createAction(CURRENCY_RATES)

function currencyInfo(state = {}, {
  type,
  payload,
}) {
  switch (type) {
    case CURRENCY_RATES:
      return { ...payload, rates: { ...payload.mainRates, ...payload.rates } }
    default:
      return state
  }
}

const SHOW_MAIN_LOADER = 'SHOW_MAIN_LOADER'
const showLoader = createAction(SHOW_MAIN_LOADER)
const DEFAULT_LOADER_STATE = false

function pageLoader(state = DEFAULT_LOADER_STATE, {
  type,
  payload,
}): boolean {
  switch (type) {
    case SHOW_MAIN_LOADER:
      return payload
    default:
      return state
  }
}

const SHOW_BOOKING_ENQUIRE = 'SHOW_BOOKING_ENQUIRE'
const showEnquire = createAction(SHOW_BOOKING_ENQUIRE)

function enquireVisible(state = false, {
  type,
  payload,
}) {
  switch (type) {
    case SHOW_BOOKING_ENQUIRE:
      return payload
    default:
      return state
  }
}

const SET_PHONE = 'SET_PHONE'
const setPhone = createAction(SET_PHONE)

function phoneNumber(state = null, {
  type,
  payload,
}) {
  switch (type) {
    case SET_PHONE:
      return payload
    default:
      return state
  }
}

const MOBILE_MENU_EXPANDED = 'MOBILE_MENU_EXPANDED'
const setMobileMenu = createAction(MOBILE_MENU_EXPANDED)

function activeMobileMenu(state = false, {
  type,
  payload,
}) {
  switch (type) {
    case MOBILE_MENU_EXPANDED:
      return payload
    default:
      return state
  }
}

const SHOW_HERO_POPUP = 'SHOW_HERO_POPUP'
const setHeroPopupVisibility = createAction(SHOW_HERO_POPUP)

function isHeroPopupVisible(state = false, {
  type,
  payload,
}) {
  switch (type) {
    case SHOW_HERO_POPUP:
      return payload
    default:
      return state
  }
}

const SET_IS_LOADING = 'SET_IS_LOADING'
const setIsLoading = createAction(SET_IS_LOADING)

function isLoading(state = true, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return payload
    default:
      return state
  }
}


export {
  setCurrency,
  selectedCurrency,
  currentUser,
  setUser,
  setRates,
  currencyInfo,
  pageLoader,
  showLoader,
  enquireVisible,
  showEnquire,
  setPhone,
  phoneNumber,
  activeMobileMenu,
  setMobileMenu,
  setHeroPopupVisibility,
  isHeroPopupVisible,
  isLoggedIn,
  setIsLoggedIn,
  setPopupHidden,
  isPopupHidden,
  setInfo,
  info,
  setIsLoading,
  isLoading,
}
