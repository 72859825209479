
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  currencyInfo,
  selectedCurrency,
  currentUser,
  isLoggedIn,
  activeMobileMenu,
  isHeroPopupVisible,
  enquireVisible,
  pageLoader,
  isPopupHidden,
  phoneNumber,
  info,
} from './actions/main-actions'
import { availabilityInfo } from './actions/availability-actions'
import { currentStep, currentFields, menuItems, statuses } from './actions/bookings-actions'

import { isMapMoved, selectedPin, isPermissionError } from './actions/landing-actions'

const rootReducer = combineReducers({
  availabilityInfo,
  info, currencyInfo, selectedCurrency, currentUser, isLoggedIn,
  activeMobileMenu,
  isHeroPopupVisible,
  enquireVisible,
  currentStep,
  currentFields,
  pageLoader,
  isPopupHidden,
  phoneNumber,
  menuItems,
  statuses,
  selectedPin,
  isMapMoved,
  isPermissionError,
})

const store = configureStore({
  reducer: rootReducer,
})

export default store
export type RootState = ReturnType<typeof rootReducer>
