import { createAction } from '@reduxjs/toolkit'

const SET_SELECTED_PIN = 'SET_SELECTED_PIN'
const setSelectedPin = createAction<number>(SET_SELECTED_PIN)

function selectedPin(state = null, {
  type,
  payload,
}) {
  switch (type) {
    case SET_SELECTED_PIN:
      return payload
    default:
      return state
  }
}

const SET_IS_MAP_MOVED = 'SET_IS_MAP_MOVED'
const setIsMapMoved = createAction<boolean>(SET_IS_MAP_MOVED)

function isMapMoved(state = false, {
  type,
  payload,
}) {
  switch (type) {
    case SET_IS_MAP_MOVED:
      return payload
    default:
      return state
  }
}

const SET_IS_PERMISSION_ERROR = 'SET_IS_PERMISSION_ERROR'
const setIsPermissionError = createAction<boolean>(SET_IS_PERMISSION_ERROR)

function isPermissionError(state = false, {
  type,
  payload,
}) {
  switch (type) {
    case SET_IS_PERMISSION_ERROR:
      return payload
    default:
      return state
  }
}

export {
  setSelectedPin,
  selectedPin,
  setIsMapMoved,
  isMapMoved,
  setIsPermissionError,
  isPermissionError,
}
