import { createAction } from 'redux-actions'
import { IGisStep } from '@/interfaces/Gis'

const SET_CURRENT_FIELDS = 'SET_CURRENT_FIELDS'
const setCurrentFields = createAction(SET_CURRENT_FIELDS)

function currentFields(state = [], { type, payload }) {
  switch (type) {
    case SET_CURRENT_FIELDS:
      return payload
    default:
      return state
  }
}

const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
const setCurrentStep = createAction(SET_CURRENT_STEP)

function currentStep(state = 'intro', { type, payload }) {
  switch (type) {
    case SET_CURRENT_STEP:
      return payload
    default:
      return state
  }
}

const SET_MENU_ITEMS = 'SET_MENU_ITEMS'
const setMenuItems = createAction(SET_MENU_ITEMS)

function menuItems(state: IGisStep[] = [], { type, payload }: { type: string, payload: IGisStep[] }) {
  switch (type) {
    case SET_MENU_ITEMS:
      return payload
    default:
      return state
  }
}

const SET_STATUSES = 'SET_STATUSES'
const setStatuses = createAction(SET_STATUSES)

function statuses(state = {}, { type, payload }) {
  switch (type) {
    case SET_STATUSES:
      return payload
    default:
      return state
  }
}

export { currentFields, setCurrentFields, setCurrentStep, currentStep, menuItems, setMenuItems, statuses, setStatuses }
