import pickBy from 'lodash/pickBy'
import moment from 'moment'
import { toast } from 'react-toastify'
import { omit } from 'lodash'
import { User } from 'interfaces/User'
import { CookieListItem } from 'next/dist/compiled/@edge-runtime/cookies'

function getCognitoCookieName(cookies): string | undefined {
  const aud = process.env.NEXT_PUBLIC_COGNITO_ID
  const sub = getCookie(`CognitoIdentityServiceProvider.${aud}.LastAuthUser`, cookies)
  return `CognitoIdentityServiceProvider.${aud}.${sub}`
}

export function getRefreshToken(cookies): string | undefined {
  return getCookie(`${getCognitoCookieName(cookies)}.refreshToken`, cookies)
}

export function getIdToken(cookies?: object): string | undefined {
  return getCookie(`${getCognitoCookieName(cookies)}.idToken`, cookies)
}

export function getOffset(el) {
  if(window && window.document) {
    const bodyRect = document.body.getBoundingClientRect()
    const elemRect = el.getBoundingClientRect()
    const offsetLeft = elemRect.left - bodyRect.left
    const offsetTop = elemRect.top - bodyRect.top

    return { top: offsetTop, left: offsetLeft }
  }
}

export function makeCounter() {
  let privateCounter = 0

  function changeBy(val) {
    privateCounter += val
  }

  function setValue(val) {
    privateCounter = val
  }

  return {
    increment: function() {
      changeBy(1)
    },
    decrement: function() {
      changeBy(-1)
    },
    setValue,
    value: function() {
      return privateCounter
    },
  }
}

export function getCookie(name: string, cookies?: CookieListItem): string {
  if(typeof document === 'undefined') {
    return cookies ? cookies[name] : ''
  }

  const a = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`)
  return a ? a[1] : ''
}

export function setCookie(name, value, options: any = {}): void {
  let expires = options.expires

  if(typeof expires === 'number' && expires) {
    const d = new Date()
    d.setTime(d.getTime() + expires * 1000)
    expires = options.expires = d
  }

  options.path = '/'

  if(expires && expires.toUTCString) {
    options.expires = expires.toUTCString()
  }

  value = encodeURIComponent(value)

  let updatedCookie = `${name}=${value}`

  // eslint-disable-next-line no-unused-vars
  for(const propName in options) {
    updatedCookie += `;${propName}`
    const propValue = options[propName]
    if(propValue !== true) {
      updatedCookie += `=${propValue}`
    }
  }

  document.cookie = updatedCookie
}

export function serializeData(params, keys = [], isArray = false): string {
  const cleanedParams = pickBy(params, (val) => {
    if(Array.isArray(val)) {
      return val.length
    } else if(typeof val === 'undefined') {
      return false
    }
    return true
  })
  const p = Object.keys(cleanedParams).map((key) => {
    const val = params[key]

    if(
        Object.prototype.toString.call(val) === '[object Object]' ||
        Array.isArray(val)
    ) {
      if(Array.isArray(params)) {
        keys.push('')
      } else {
        keys.push(key)
      }
      return serializeData(val, keys, Array.isArray(val))
    } else {
      let tKey = key

      if(keys.length > 0) {
        const tKeys = isArray ? keys : [...keys, key]
        tKey = tKeys.reduce((str, k) => {
          return str === '' ? k : `${str}[${k}]`
        }, '')
      }
      if(typeof val !== 'undefined') {
        try {
          return `${tKey}=${val}`
        } catch (e) {
          console.error(e)
        }
      }
    }

    return undefined
  }).join('&')
  keys.pop()
  return p
}

export function isMobile(): boolean {
  if(typeof navigator === 'undefined') {
    return false
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
  )
}

type CalendarLocale = {
  localize: {
    month: (n: number) => string
    day: (n: number) => number
  }
  formatLong: object
}

export function getCalendarLocale(locale): CalendarLocale {
  const lang = locale === 'ach' ? 'en' : locale
  const months = moment.localeData(lang).months()

  return {
    localize: {
      month: (n) => months[n],
      day: (n) => n,
    },
    formatLong: {
     date: () => 'mm/dd/yyyy',
    },
  }
}

export function roundPrice(amount): string {
  return (Math.round(amount * 100) / 100).toFixed(2)
}

export function getKindVerbose(kind): 'Dive Center' | 'Liveaboard' | 'Dive Resort' {
  if(kind === 0) {
    return 'Dive Center'
  } else if(kind === 10) {
    return 'Liveaboard'
  }
  return 'Dive Resort'
}

export function isIOsSafari(): RegExpMatchArray {
  return (
      navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
      navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
  )
}

export function isIOS(): RegExpMatchArray {
  return navigator.userAgent.match(/iPhone|iPad|iPod/i)
}

export const target = isMobile() ? '_self' : '_blank'

export function isElementInViewport(el: HTMLElement): boolean {
  let top = el.offsetTop
  let left = el.offsetLeft
  const width = el.offsetWidth
  const height = el.offsetHeight

  while(el.offsetParent) {
    el = el.offsetParent as HTMLElement
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
  )
}

export function getProductionAssets(assetPath: string): string {
  const [folderName] = assetPath.split('.')
  if(process.env.NODE_ENV === 'production') {
    return `${process.env.NEXT_PUBLIC_AWS_S3_CUSTOM_DOMAIN}/${folderName}/${assetPath}`
  }

  return `${window.location.origin}/${assetPath}`
}

export function generateAffiliateUrl(user: User): string {
  if(!user?.affiliate) {
    return
  }
  let query = '?'
  const affiliateIdText = document.getElementById('current-affiliate-id')
  const affiliateQueries = [
    'aid',
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_content',
  ]

  if(affiliateIdText) {
    affiliateIdText.innerText = user.affiliate.memberNumber
  }

  if(window.location.search) {
    const queryParams = parseQuery()
    const cleanedQueryParams = omit(queryParams, function(val, key) {
      return affiliateQueries.indexOf(key) !== -1
    })

    query += serializeData(cleanedQueryParams)
  }

  if(query.length > 1) {
    query += '&'
  }

  query += `aid=${user.affiliate.memberNumber}&utm_campaign=ww-all-all-affiliates-shops&utm_source=affiliate_${user.affiliate.memberNumber}&utm_medium=link&utm_content=direct`

  return window.location.origin + window.location.pathname + query
}

export function parseQuery(): object {
  if(!window) {return {}}
  const qs = window.location.search.replace('?', '')
  const items = qs.split('&')

  if(!qs) {
    return {}
  }

  // Consider using   reduce to create the data mapping
  return items.reduce((data, item) => {
    const splittedItem = item.split('=')
    const key = splittedItem[0]

    if(!key) {
      return data
    }

    const value =
        splittedItem.length > 2
            ? window.decodeURIComponent(splittedItem.slice(1).join('='))
            : splittedItem[1]
    // Sometimes a query string can have multiple values
    // for the same key, so to factor that case in, you
    // could collect an array of values for the same key
    if(data[key] !== undefined) {
      // If the value for this key was not previously an
      // array, update it
      if(!Array.isArray(data[key])) {
        data[key] = [decodeURIComponent(data[key])]
      }
      data[key].push(decodeURIComponent(value))
    } else {
      data[key] = decodeURIComponent(value)
    }

    return data
  }, {})
}

export function copyTextToClipboard(text: string, messageOnSuccess: string): void {
  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.opacity = '0'
  textArea.style.position = 'absolute'
  textArea.style.top = '-999px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
    toast.success(messageOnSuccess,
        {
          position: toast.POSITION.TOP_CENTER,
        },
    )
  } catch (err) {
    console.error(err)
  }

  document.body.removeChild(textArea)
}


export function checkIsExist(params: unknown[]): boolean {
  return params.every(Boolean)
}

export function getImagePath(imgPath) {
  const path = process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_AWS_S3_CUSTOM_DOMAIN : ''
  return `${path}/next-images/${imgPath}`
}

export function scrollToError() {
  setTimeout(() => {
    const el = document.querySelector('.error-field')
    if(el) {
      window.preventScrolling = true
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })

      setTimeout(() => {
        window.preventScrolling = false
      }, 1000)
    }
  }, 100)
}
